import { defineModule, isFunction } from '../utils/helpers';

declare const show_cookie_banner: unknown;
declare const cmplz_set_category_as_body_class: unknown;

export default defineModule(() => {
  try {
    if (isFunction(show_cookie_banner)) {
      show_cookie_banner();
    }
    if (isFunction(cmplz_set_category_as_body_class)) {
      cmplz_set_category_as_body_class();
    }
  }
  catch(err) {};
});

document.addEventListener("cmplz_banner_status", function(consentData) {
  var status = consentData.detail;
  if ( status === 'dismissed' ) {
      document.querySelector('body').classList.remove('cmplz-noscroll');
  } else {
      document.querySelector('body').classList.add('cmplz-noscroll');
  }
});

document.addEventListener("cmplz_cookie_warning_loaded", function() {
  if ( cmplz_get_banner_status()!=='dismissed' ) {
      document.querySelector('body').classList.add('cmplz-noscroll');
  }
});
