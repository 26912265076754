import DOMPurify from 'dompurify';
import type { MultipleSelectInstance } from 'multiple-select-vanilla';
import { defineModule } from '@/js/utils/helpers';

const selects: MultipleSelectInstance[] = [];

const getElements = () => ({
  selectElements: document.querySelectorAll<HTMLSelectElement>('select'),
});

export default defineModule(
  async () => {
    const { selectElements } = getElements();
    if (!selectElements.length) return;

    const { multipleSelect } = await import('multiple-select-vanilla');

    selectElements.forEach((selectElement) => {
      const select = multipleSelect(selectElement, {
        selectAll: false,
        renderOptionLabelAsHtml: true,
        sanitizer: (html) =>
          DOMPurify.sanitize(html, { RETURN_TRUSTED_TYPE: true }), // return TrustedHTML Type
        hideOptgroupCheckboxes: true,
      }) as MultipleSelectInstance;

      //   Prevents a bug where search text was already visible?
      select.close();

      selects.push(select);
    });
  },
  () => {
    while (selects.length) {
      selects.pop()?.destroy();
    }
  },
);
