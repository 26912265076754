import type { MultipleSelectInstance } from 'multiple-select-vanilla';
import { defineModule } from '@/js/utils/helpers';
import { BREAKPOINT_MEDIA_QUERIES } from '@/js/utils/breakpoints';

interface MultiSelectElement extends HTMLSelectElement {
  _multipleSelect?: MultipleSelectInstance;
}

const getElements = () => ({
  filterElement: document.querySelector<HTMLElement>(
    '.content-block--post-archive #filter',
  ),
  filterCategorySelect: document.querySelector<HTMLSelectElement>(
    '.content-block--post-archive #filter select[name=categories]',
  ),
  filterCategoryInputs: document.querySelectorAll<HTMLInputElement>(
    '.content-block--post-archive #filter input[type=checkbox][name=categories]',
  ),
});

const filterBreakpointChecker = () => {
  const { filterElement, filterCategorySelect, filterCategoryInputs } =
    getElements();
  if (!filterElement || !filterCategorySelect || !filterCategoryInputs) return;

  // eslint-disable-next-line no-underscore-dangle
  const categoriesSelect = (filterCategorySelect as MultiSelectElement)
    ._multipleSelect;
  if (!categoriesSelect) return;

  const categoriesInputs = [...filterCategoryInputs];

  if (!BREAKPOINT_MEDIA_QUERIES.md.matches) {
    // desktop -> mobile
    const inputValue = categoriesInputs.find((input) => input.checked)?.value;
    categoriesSelect.setSelects([inputValue]);
    categoriesSelect.close();
    return;
  }

  const matchingInput = categoriesInputs.find(
    (input) => input.value === categoriesSelect.getSelects().at(0),
  );
  if (!matchingInput) return;

  matchingInput
    .closest<HTMLLabelElement>('label')
    ?.dispatchEvent(new Event('click'));
};

const onCategoryInputChange = ({ currentTarget }: Event) => {
  const { filterCategoryInputs } = getElements();
  const changedInput = currentTarget as HTMLInputElement;

  filterCategoryInputs.forEach((input) => {
    if (input === changedInput) {
      if (input.checked) {
        input.disabled = true;
      }
      return;
    }

    input.checked = false;
    input.disabled = false;
  });
};

export default defineModule(
  async () => {
    const { filterElement, filterCategoryInputs } = getElements();
    if (!filterElement) return;

    filterCategoryInputs.forEach((input) =>
      input.addEventListener('change', onCategoryInputChange, {
        passive: true,
      }),
    );

    BREAKPOINT_MEDIA_QUERIES.md.addEventListener(
      'change',
      filterBreakpointChecker,
      {
        passive: true,
      },
    );
  },

  () => {
    const { filterElement, filterCategoryInputs } = getElements();
    if (!filterElement) return;

    filterCategoryInputs.forEach((input) =>
      input.removeEventListener('change', onCategoryInputChange),
    );

    BREAKPOINT_MEDIA_QUERIES.md.removeEventListener(
      'change',
      filterBreakpointChecker,
    );
  },
);
