import { defineModule } from '@/js/utils/helpers';
import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';

const getElements = () => ({
  ctaStylingElements: document.querySelectorAll<HTMLElement>(
    '[data-button],.content--block',
  ),
  ctaGroup: document.querySelector<HTMLElement>('.cta'),
  ctaButton: document.querySelector<HTMLElement>('.cta .btn'),
  footer: document.querySelector<HTMLElement>('.page-footer'),
  phoneForm: document.getElementById('footer-phone-form'),
});

const updateCtaButtonStyles = (overlappingElement?: HTMLElement) => {
  const { ctaButton } = getElements();
  if (!ctaButton) return;

  ['btn--secondary'].forEach((color) => {
    ctaButton.classList.toggle(
      color,
      color === (overlappingElement?.dataset.button ?? ''),
    );
  });
};

let ctaButtonObserver: IntersectionObserver;
const ctaButtonIntersectingElements = new Set<HTMLElement>();

const initCallButtonObserver = () => {
  const { ctaStylingElements } = getElements();
  if (!ctaStylingElements) return;

  const rootMargin = `${-1 * window.innerHeight + 52}px ${0}px ${-1 * 52 + 48}px ${0}px`;

  ctaButtonObserver = new IntersectionObserver(
    (entries) => {
      let bestMatch: HTMLElement | undefined;
      entries.forEach((entry) => {
        const el = entry.target as HTMLElement;
        if (entry.isIntersecting) {
          bestMatch = el;
          ctaButtonIntersectingElements.add(el);
        } else {
          ctaButtonIntersectingElements.delete(el);
        }
      });

      if (!bestMatch && ctaButtonIntersectingElements.size > 0) {
        bestMatch = [...ctaButtonIntersectingElements.values()].shift();
      }

      updateCtaButtonStyles(bestMatch);
    },
    {
      threshold: 0,
      rootMargin,
    },
  );

  ctaStylingElements.forEach((sec) => ctaButtonObserver.observe(sec));
};

const onScroll = () => {
  const { ctaGroup, footer } = getElements();

  if (footer) {
    const rect = footer.getBoundingClientRect();
    if (rect.top <= window.innerHeight && rect.bottom >= 0) {
      ctaGroup?.classList.add('cta--scroll');
    } else {
      ctaGroup?.classList.remove('cta--scroll');
    }
  }
};

const scrollToContactForm = () => {
  const { phoneForm } = getElements();

  if (phoneForm) {
    phoneForm.scrollIntoView({ behavior: 'smooth' });
  }
};

const ctaBreakpointChecker = (event: MediaQueryListEvent) => {
  const { ctaButton } = getElements();
  if (!ctaButton) return;

  if (event.matches) {
    initCallButtonObserver();
  } else {
    ctaButtonObserver?.disconnect();
    ctaButtonIntersectingElements.clear();
    updateCtaButtonStyles();
  }
};

export default defineModule(
  () => {
    const { ctaButton } = getElements();
    if (!ctaButton) return;

    window.addEventListener('scroll', onScroll);
    ctaButton?.addEventListener('click', scrollToContactForm);

    if (BREAKPOINT_MEDIA_QUERIES.md.matches) {
      initCallButtonObserver();
    }

    BREAKPOINT_MEDIA_QUERIES.md.addEventListener(
      'change',
      ctaBreakpointChecker,
      { passive: true },
    );
  },
  () => {
    const { ctaButton } = getElements();

    window.removeEventListener('scroll', onScroll);
    ctaButton?.removeEventListener('click', scrollToContactForm);

    BREAKPOINT_MEDIA_QUERIES.md.removeEventListener(
      'change',
      ctaBreakpointChecker,
    );

    ctaButtonObserver?.disconnect();
    ctaButtonIntersectingElements.clear();
  },
);
