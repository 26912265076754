import type Swiper from 'swiper';
import { defineModule } from '@/js/utils/helpers';
import { BREAKPOINTS } from '@/js/utils/breakpoints';

const swipers: Swiper[] = [];

const getElements = () => ({
  swiperElements: document.querySelectorAll<HTMLElement>(
    '.content-block--team-members-slider .swiper',
  ),
});

export default defineModule(
  async () => {
    const { swiperElements } = getElements();
    if (!swiperElements) return;
    const [{ Swiper }] = await Promise.all([
      import('swiper/core'),
      import('swiper/modules'),
    ]);

    swiperElements.forEach((swiperElement) => {
      const swiper = new Swiper(swiperElement, {
        slidesPerView: 'auto',
        spaceBetween: 16,
        watchSlidesProgress: true,
        breakpoints: {
          [BREAKPOINTS.lg]: {
            spaceBetween: 24,
          },
        },

        grabCursor: true,
      });
      swipers.push(swiper);
      swiper.on('slideChange', function () {
        swiperElement.classList.toggle('after:opacity-0', swiper.isEnd);
      });
    });
  },

  () => {
    while (swipers.length) {
      swipers.pop()?.destroy(true, false);
    }
  },
);
