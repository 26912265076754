import { defineModule } from '@/js/utils/helpers';

const getElements = () => ({
  expandableTrigger: document.querySelector<HTMLElement>(
    '.expandable__trigger',
  ),
  expandable: document.querySelector<HTMLElement>('.expandable'),
  footer: document.querySelector<HTMLElement>('footer'),
  interactiveElements: document.querySelectorAll<HTMLElement>(
    'input, textarea, select, button',
  ),
});

const setTabIndex = (value: number) => {
  Array.from(getElements().interactiveElements)
    .filter(
      (el) =>
        getElements().expandable?.contains(el) &&
        el !== getElements().expandableTrigger,
    )
    .forEach((el) => el.setAttribute('tabindex', value.toString()));
};

const handleClick = () => {
  const { expandable, footer } = getElements();
  if (!expandable || !footer) return;

  const isOpen = expandable.classList.toggle('expandable--open');
  setTabIndex(isOpen ? 0 : -1);

  if (!isOpen) footer.scrollIntoView({ behavior: 'smooth' });
};

export default defineModule(
  () => {
    setTabIndex(-1);
    getElements().expandableTrigger?.addEventListener('click', handleClick, {
      passive: true,
    });
  },
  () => {
    getElements().expandableTrigger?.removeEventListener('click', handleClick);
  },
);
