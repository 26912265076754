import Cookies from 'js-cookie';
import { defineModule } from '../../utils/helpers';
import { toggleModal } from '.';

const getElements = () => ({
  popupElements: document.querySelectorAll<HTMLElement>('[data-popup-id]'),
});

const checkPopups = () => {
  const { popupElements } = getElements();

  return [...popupElements].some((popup) => {
    const { popupId } = popup.dataset;

    if (!Cookies.get(`popup-${popupId}`)) {
      popup.classList.remove('!hidden');
      toggleModal('popup', true);
      return true;
    }

    return false;
  });
};

const dismissPopup = ({ currentTarget }: Event) => {
  if (!(currentTarget instanceof HTMLElement)) return;

  const popup = currentTarget.closest<HTMLElement>('[data-popup-id]');
  if (!popup) return;

  const { popupId, popupGlobal } = popup.dataset;

  popup.classList.add('!hidden');
  Cookies.set(`popup-${popupId}`, 'dismissed', {
    expires: 1,
    path: popupGlobal !== undefined ? '/' : undefined,
  });

  toggleModal('popup', false);

  checkPopups();
};

export default defineModule(
  () => {
    const { popupElements } = getElements();

    popupElements.forEach((popupEl) => {
      popupEl
        ?.querySelectorAll<HTMLElement>('[data-dismiss-modal], .cta_button')
        .forEach((element) => {
          element.addEventListener('click', dismissPopup);
        });
    });

    checkPopups();
  },
  () => {
    const { popupElements } = getElements();

    popupElements.forEach((popupEl) => {
      popupEl
        ?.querySelectorAll<HTMLElement>('[data-dismiss-modal], .cta_button')
        .forEach((element) => {
          element.removeEventListener('click', dismissPopup);
        });
    });

    toggleModal('popup', false);
  },
);
