import { createFocusTrap, type FocusTrap } from 'focus-trap';
import { BREAKPOINT_MEDIA_QUERIES } from '@/js/utils/breakpoints';
import { defineModule, nextTick } from '@/js/utils/helpers';
import { hasOpenOverlay, Overlay, toggleOverlay } from '@/js/utils/overlays';

let navbarFocusTrap: FocusTrap | null = null;

let lastScrollTop = 0;
const SCROLL_TRESHOLD = 1280;
const MENU_TIMING = 500;

const getElements = () => ({
  navbar: document.querySelector<HTMLElement>('.navbar'),
  navbarBrand: document.querySelector<HTMLElement>('.navbar__brand'),
  navbarToggle: document.querySelector<HTMLButtonElement>(
    '.navbar__nav > button[aria-controls]',
  ),
  navbarStylingElements: document.querySelectorAll<HTMLElement>(
    '[data-brand],[data-toggle],.content--block',
  ),
});

const toggleNavTransitions = (force: boolean) => {
  const { navbar } = getElements();
  if (!navbar) return;

  navbar.classList.toggle('navbar--no-transitions', force);
};

let closingTimeout: NodeJS.Timeout;
const toggleNavButton = (
  e: Event,
  force?: boolean,
  button?: HTMLButtonElement,
) => {
  const { navbar } = getElements();
  if (!navbar) return;

  const btn = button ?? (e.currentTarget as HTMLButtonElement);
  const expand = force ?? btn.ariaExpanded === 'false';
  const closing = !expand && btn.ariaExpanded === 'true';

  toggleOverlay(Overlay.MENU, expand);
  navbar.classList.toggle('menu--open', expand);

  clearTimeout(closingTimeout);
  if (closing) {
    navbar.classList.add('menu--closing');
    closingTimeout = setTimeout(() => {
      navbar.classList.remove('menu--closing');
    }, MENU_TIMING);
  } else {
    navbar.classList.remove('menu--closing');
  }

  btn.ariaExpanded = String(expand);

  if (expand) {
    navbarFocusTrap?.activate();
  } else {
    navbarFocusTrap?.deactivate();
  }
};

const closeMenu = (e: Event) => {
  const { navbar, navbarToggle } = getElements();
  if (!navbar || !navbarToggle) return;

  if (e.type === 'mouseleave' && !BREAKPOINT_MEDIA_QUERIES.md.matches) return;

  toggleNavButton(e, false, navbarToggle);
};

const navbarBreakpointChecker = (e: Event) => {
  const { navbarToggle: menuToggle } = getElements();
  if (!menuToggle) return;

  toggleNavTransitions(true);

  closeMenu(e);

  nextTick(() => {
    toggleNavTransitions(false);
  });
};

const updateNavbarStyles = (overlappingElement?: HTMLElement) => {
  const { navbarBrand, navbarToggle } = getElements();
  if (!navbarBrand || !navbarToggle) return;

  [
    // add or remove color classes on the relevant nav elements that require dynamic colors
    'text-gray',
  ].forEach((color) => {
    navbarBrand.classList.toggle(
      color,
      color === (overlappingElement?.dataset.brand ?? ''),
    );
    navbarToggle.classList.toggle(
      color,
      color === (overlappingElement?.dataset.toggle ?? ''),
    );
  });
};

let navbarObserver: IntersectionObserver;
const navbarIntersectingElements = new Set<HTMLElement>();

const initNavbarObserver = () => {
  // are there any elements that can change the navbar styling?
  const { navbarStylingElements } = getElements();
  if (!navbarStylingElements) return;

  // restrict viewport the relevant strip that contains the brand logo + toggle button
  const rootMargin = `${-40 - 8}px ${0}px ${-1 * window.innerHeight + 40 + 24}px ${0}px`;

  navbarObserver = new IntersectionObserver(
    (entries) => {
      let bestMatch: HTMLElement | undefined;
      entries.forEach((entry) => {
        const el = entry.target as HTMLElement;
        if (entry.isIntersecting) {
          bestMatch = el;
          navbarIntersectingElements.add(el);
        } else {
          navbarIntersectingElements.delete(el);
        }
      });

      if (!bestMatch && navbarIntersectingElements.size > 0) {
        bestMatch = [...navbarIntersectingElements.values()].shift();
      }

      // update styles with most overlapping element if any
      updateNavbarStyles(bestMatch);
    },
    {
      threshold: 0,
      rootMargin,
    },
  );

  // Observe each section/header
  navbarStylingElements.forEach((sec) => navbarObserver.observe(sec));
};

const onScroll = () => {
  if (hasOpenOverlay(Overlay.MENU)) return;

  const { navbar } = getElements();

  const currentScrollTop =
    window.pageYOffset || document.documentElement.scrollTop;

  const toggleClass =
    currentScrollTop > SCROLL_TRESHOLD && currentScrollTop > lastScrollTop;

  navbar?.classList.toggle('navbar--scroll', toggleClass);

  lastScrollTop = currentScrollTop;
};

export default defineModule(
  () => {
    const { navbar, navbarToggle } = getElements();
    if (!navbar || !navbarToggle) return;

    navbarFocusTrap = createFocusTrap(navbar, {
      allowOutsideClick: false,
      escapeDeactivates: true,
      onDeactivate: () => {
        // already closing
        if (navbar.classList.contains('menu--closing')) return;

        closeMenu(new Event('focustrap:deactivate'));
      },
    });

    initNavbarObserver();

    navbarToggle.addEventListener('click', toggleNavButton, { passive: true });

    document.addEventListener('swup:link:self', closeMenu, { passive: true });
    window.addEventListener('scroll', onScroll);

    BREAKPOINT_MEDIA_QUERIES.md.addEventListener(
      'change',
      navbarBreakpointChecker,
      { passive: true },
    );
  },
  () => {
    const { navbar } = getElements();
    if (!navbar) return;

    navbarFocusTrap?.deactivate({
      returnFocus: false,
      onDeactivate: () => {
        // do nothing
      },
    });

    BREAKPOINT_MEDIA_QUERIES.md.removeEventListener(
      'change',
      navbarBreakpointChecker,
    );

    document.removeEventListener('swup:link:self', closeMenu);
    window.removeEventListener('scroll', onScroll);

    toggleOverlay(Overlay.MENU, false);

    navbarObserver.disconnect();
    navbarIntersectingElements.clear();
  },
);
