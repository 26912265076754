import type { createAccordion } from '@/js/utils/accordion';

import { defineModule } from '@/js/utils/helpers';

const accordions: ReturnType<typeof createAccordion>[] = [];

const getElements = () => ({
  accordionGroups: document.querySelectorAll<HTMLElement>(
    '.content-block .accordion-group',
  ),
});

export default defineModule(
  async () => {
    const { accordionGroups } = getElements();
    if (!accordionGroups) return;

    const { createAccordion } = await import('@/js/utils/accordion');

    accordionGroups.forEach((accordionGroup) => {
      const groupedAccordionElements =
        accordionGroup.querySelectorAll<HTMLElement>('.accordion');

      groupedAccordionElements.forEach((accordionElement) => {
        const startOpen =
          accordionElement.classList.contains('accordion--open');

        const accordion = createAccordion(accordionElement, {
          onToggle: (open) => {
            if (!open) return;

            accordions
              .filter(
                (acc) =>
                  acc.el !== accordionElement &&
                  [...groupedAccordionElements].includes(acc.el),
              )
              .forEach((acc) => acc.close());
          },
        });

        if (startOpen) {
          accordion.open();
        }

        accordions.push(accordion);
      });
    });
  },
  () => {
    while (accordions.length) {
      accordions.pop()?.destroy();
    }
  },
);
